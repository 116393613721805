<template>
  <div class="card card-custom gutter-b">
<!--    <div v-if="checkIncoming" class="position-absolute shadow-lg p-3 mb-5 bg-white rounded" style="right: 520px; top: -80px; z-index: 1000">-->
<!--      <div class="">-->
<!--        <div v-if="!dialogAssignTicket" class="d-flex">-->
<!--          <modal-create-ticket @create-done="createSussess" :sources="sources" :departments="departments" :objectFeedback="objectFeedback"></modal-create-ticket>-->
<!--          <button class="btn btn-success font-weight-bold btn-sm ml-4" @click="dialogAssignTicket = true">-->
<!--            <i class="fas fa-ticket-alt"></i>-->
<!--            Gán ticket-->
<!--          </button>-->
<!--        </div>-->
<!--        <div v-if="dialogAssignTicket">-->
<!--          <h6>Gán cuộc gọi vào ticket</h6>-->
<!--          <div class="p-4">-->
<!--            <div>-->
<!--              <label>Mã ticket</label>-->
<!--            </div>-->
<!--            <el-select-->
<!--              v-model="formTicket.ticket_id"-->
<!--              filterable-->
<!--              clearable-->
<!--              remote-->
<!--              reserve-keyword-->
<!--              placeholder="Nhập số điện thoại"-->
<!--              :remote-method="remoteMethod"-->
<!--              :loading="search_loading">-->
<!--              <el-option-->
<!--                :key="dataFindTicket.id"-->
<!--                :label="changeTitle(dataFindTicket)"-->
<!--                :value="dataFindTicket.id">-->
<!--                <span>{{ changeTitle(dataFindTicket) }}</span>-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </div>-->
<!--          <div class="mt-4 d-flex justify-content-end">-->
<!--            <el-button class=""@click="dialogAssignTicket = false">Huỷ bỏ</el-button>-->
<!--            <el-button class="bg-violet text-white" :disabled="loadingAssignee" v-loading="loadingAssignee" @click="assigneeTicket">Xác nhận</el-button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div v-if="!checkChangeIcon" class="position-absolute shadow-lg mb-5 rounded" style="right: 280px;top: -127px;z-index: 1000;">-->
<!--      <button class="border border-dark p-3 rounded" @click="changeIcon">-->
<!--          <i class="el-icon-phone-outline"></i>-->
<!--      </button>-->
<!--    </div>-->
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label fw-bold">Chăm sóc học viên</h3>
      </div>

      <div v-if="currentUser.user.permission.includes(permission.EXPORT_TICKET)">
        <button class="btn btn-success font-weight-bold mt-5" @click="exportExcel"
          :class="{ 'spinner spinner-white spinner-right': is_export }">
          <i class="la la-file-excel"></i>Xuất excel
        </button>
        <button class="btn btn-success font-weight-bold mt-5 ml-2" @click="exportExcelV2"
                :class="{ 'spinner spinner-white spinner-right': is_export }">
          <i class="la la-file-excel"></i>Xuất excel v2
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label>Mã ticket</label>
            <el-input placeholder="Mã ticket" v-model="query.ticket_id"></el-input>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Danh mục</label>
            <el-select @input="checkShowReason" v-model="query.category" filterable class="w-100" placeholder="Danh mục"
              clearable>
              <el-option v-for="item in categories" :key="item.id" :label="item.name" :value="item.id">
                <span style="float: left">{{ item.name }}</span>
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Khách hàng</label>
            <el-input placeholder="Nhập tên, mã, số điện thoại" v-model="query.keyword"></el-input>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Trạng thái</label>
            <el-select v-model="query.status" filterable class="w-100" placeholder="Trạng thái" clearable multiple
              collapse-tags>
              <el-option v-for="item in status" :key="item.id" :label="item.name" :value="item.id">
                <span style="float: left">{{ item.name }}</span>
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Trung tâm</label>
            <el-select v-model="query.center_ids" filterable class="w-100" placeholder="Chọn trung tâm"
              @input="handleCenter" multiple collapse-tags clearable>
              <el-option v-for="item in centers" :key="item.id" :label="item.name" :value="item.id">
                <span style="float: left">{{ item.name }}</span>
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <div class="form-group w-100">
              <label>Trạng thái bình luận</label>
              <el-select v-model="query.status_feedback" filterable class="w-100" placeholder="Chọn trạng thái bình luận" clearable>
                <el-option label="Chưa bình luận" :value="0"> </el-option>
                <el-option label="Có bình luận mới" :value="1"> </el-option>
                <el-option label="Đã bình luận" :value="2"> </el-option>
              </el-select>
            </div>
          </div>
        </div>  
        <div class="col-md-3">
          <div class="form-group">
            <label>Thời gian tạo</label>
            <date-picker placeholder="Thời gian tạo" range v-model="query.created_at" format="DD-MM-YYYY"
              valueType="YYYY-MM-DD"></date-picker>
          </div>
        </div>
        <div class="col-md-2 mt-8">
          <div class="d-flex justify-content-between">
            <button type="button" class="btn btn-primary font-weight-bold mr-2" :disabled="is_disable_search"
              :class="{ 'spinner spinner-white spinner-left': is_disable_search }" @click="search">
              Tìm kiếm
            </button>
          </div>
        </div>
      </div>

      <div class="example mb-10">
        <div class="example-preview table-responsive ">
          <table class="table table-vertical-center">
            <thead class="table-secondary">
              <tr>
                <th class="min-w-50px" scope="col">Mã ticket</th>
                <th scope="col" class="min-w-150px">Danh mục</th>
                <th scope="col" class="min-w-200px">Chi nhánh học</th>
                <th scope="col" class="min-w-100px">Lớp học</th>
                <th scope="col" class="min-w-120px">Khách hàng</th>
                <th scope="col" class="min-w-120px">Phòng ban Xử lý</th>
                <th scope="col" class="min-w-120px">Trạng thái</th>
                <th scope="col" class="min-w-150px">Trạng thái bình luận</th>
                <th scope="col" class="min-w-150px">Thời gian tạo</th>
                <th scope="col" class="min-w-80px">Hành động</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in tickets.data" :key="index">
                <td>{{ item.id }}</td>
                <td>
                  <span>{{ changeCategoryName(item.category_chamsoc) }}</span>
                </td>
                <td>{{ item.branch && item.branch.center ? item.branch.name : '' }}</td>
                <td>{{ item.classes ? item.classes.name : '' }}</td>
                <td>{{ item.student_edutalk ? item.student_edutalk.name + ' - ' + item.student_edutalk.phone : '' }}</td>
                <td>{{ phong_ban_xu_ly_define[item.phong_ban_xu_ly] ? phong_ban_xu_ly_define[item.phong_ban_xu_ly] : 'TTHT' }}</td>
                <td><span :class="statusDefineCss[item.status]">{{ statusDefine[item.status] }}</span></td>
                <td>
                  <span :class="statusFeedback[item.status_comment]">{{ statusFeedbackDefine[item.status_comment] }}</span>
                </td>
                <td>{{ item.created_at | formatDateTime }}</td>
                <td>
                  <router-link :to="{ name: 'ticket-show', params: { id: item.id } }" title="Xem"
                    v-if="currentUser.user.permission.includes(permission.EXPORT_TICKET)"
                    class="btn btn-sm btn-icon  btn-outline-info"><i class="fas far fa-eye"></i>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="position-absolute" style="right:40px">
              Tổng số bản ghi: <b style="color:#3699FF">{{ this.tickets.total }}</b>
        </div>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
      <paginate v-model="page" :page-count="last_page" :page-range="3" :margin-pages="1" :click-handler="clickCallback"
        :prev-text="'Trước'" :next-text="'Sau'" :container-class="'pagination b-pagination'" :pageLinkClass="'page-link'"
        :next-link-class="'next-link-item'" :prev-link-class="'prev-link-item'" :prev-class="'page-link'"
        :next-class="'page-link'" :page-class="'page-item'">
      </paginate>
    </div>
  </div>
</template>

<script>
import {
  EXPORT_EXCEL_TICKET,
  EXPORT_EXCEL_TICKET_V2,
  GET_ALL_TICKET,
  GET_TICKET_REASON_BY_TYPE,
  GET_SOURCE,
  GET_TICKET_CATEGORIES,
  GET_OBJECT_FEEDBACK,
  REASON_ERROR,
  CATEGORY
} from "../../../core/services/store/user/ticket.module";
import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { GET_CENTERS } from "../../../core/services/store/center/centers.module";
import { GET_BRANCHES } from "../../../core/services/store/center/branch.module";
import { GET_COURSES } from "../../../core/services/store/course/course.module";
import { GET_CLASSES } from "../../../core/services/store/course/classes.module";
import { mapGetters } from "vuex";
import {exportExcel} from "../../../core/filters";
import ModalCreateTicket from "../crm/tickets/components/ModalCreateTicket";
import {GET_DEPARTMENT} from "../../../core/services/store/user/department.module";

export default {
  name: "TicketIndex",
  components: {
    DatePicker,
    ModalCreateTicket
  },
  data() {
    return {
      page: 1,
      last_page: 1,
      is_disable_search: false,
      is_export: false,
      is_show_reason: false,
      is_show_reasons: false,
      tickets: [],
      listReason: '',
      query: {
        keyword: '',
        ticket_id: '',
        category: '',
        status: [],
        created_at: '',
        is_auto: [1],
        category_chamsoc: [1, 2, 6, 11, 10, 13, 14, 15],
        reason_id: '',
      },
      categories: [
        {
          id: 1,
          name: "Nghỉ học 2 buổi"
        },
        {
          id: 2,
          name: "Điểm kiểm tra thấp"
        },
        {
          id: 6,
          name: "Feedback thấp"
        },
        // {
        //   id: 10,
        //   name: "Kết quả BTVN không tốt"
        // },
        {
          id: 13,
          name: "Nhắc nhở bảo hành"
        },
        {
          id: 14,
          name: "Nghỉ 1 buổi không phép"
        },
        {
          id: 15,
          name: "Nghỉ buổi khai giảng"
        },
        {
          id: 16,
          name: "Chăm sóc học viên giữa khóa"
        },
      ],
      branches: [],
      centers: [],
      courses: [],
      classes: [],
      status: [
        {
          id: 1,
          name: "Chờ xử lý"
        },
        {
          id: 2,
          name: "Đã nhập giải pháp"
        },
        {
          id: 3,
          name: "Đã hoàn thành"
        }
      ],
      phong_ban_xu_ly_define: {
        1: 'TTHT',
        2: 'Tài chính',
        3: 'Nhân sự',
        4: 'SPNN',
        5: 'CNTT',
        6: 'Đối tác',
        7: 'STVT',
        8: 'Đào tạo',
        9: 'Brand',
        10: 'Digital',
        11: 'Marketing',
        12: 'R&D',
        13: 'Pháp lý',
        14: 'QLHV',
        15: 'CSVC',
      },
      statusFeedbackDefine: {
        0: "Chưa bình luận",
        1: "Có bình luận mới",
        2: "Đã bình luận",
      },
      statusFeedback: {
        0: "badge badge-info",
        1: "badge badge-danger",
        2: "badge badge-success",
      },
      statusDefine: {
        1: "Chờ xử lý",
        2: "Đã nhập giải pháp",
        3: "Hoàn thành",
      },
      statusDefineCss: {
        1: "badge badge-warning",
        2: "badge badge-primary",
        3: "badge badge-success",
      },
      show: {
        lesson_leave: false,
        feedback_star: false,
        feedback_comment: false,
        exam_type: false,
        exam_total_score: false
      },
      showFilter: {
        star: false,
        exam: false
      },
      reasons: [],
      text_reason: '',
      config: {
        showMode: 'full',
        top: 60,
        right: 200,
        arrowLeft: 155,
        arrowDisplay: 'top',
        fromNumbers: [
          { alias: 'Number-1', number: '+84899199586' },
          { alias: 'Number-2', number: '+2222' }
        ],
        askCallTypeWhenMakeCall: false,
        appendToElement: null,
        makeAndReceiveCallInNewPopupWindow: false
      },
      accessToken: '',
      numbers: '',
      fromNumber: '',
      toNumber: '',
      callType: '',
      userByPhone: {},
      dialogCreateTicket: false,
      dialogAssignTicket: false,
      formTicket: {
        ticket_id: '',
      },
      fileList: [],
      sources: [],
      departments: [],
      objectFeedback: [],
      callStringeeId: '',
      checkIncoming: false,
      checkChangeIcon: false,
      messageError: '',
      reasonError: REASON_ERROR,
      loadingAssignee: false,
      dataFindTicket: {},
      search_loading: false,
      category: CATEGORY,
      category_chamsoc_define: {
        1: 'Nghỉ học 2 buổi',
        2: 'Điểm kiểm tra thấp',
        3: 'Không làm bài',
        4: 'Chua đóng tiền học',
        5: 'Đã đóng tiền học',
        6: 'Feedback thấp',
        7: 'Nghỉ học 1 buổi',
        8: 'Nghỉ học 1 buổi',
        9: 'Hướng dẫn app cho phụ huynh',
        10: 'Kết quả BTVN không tốt',
        11: 'Kết quả BTVN không tốt'
      },
    }
  },
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý ticket", route: 'ticket-index' },
      { title: "Chăm sóc học viên" }
    ]);
    // this.initStringeeSoftPhone();
    // this.connectStringeeSoftPhone();

  },
  mounted() {
    if ($("div").hasClass('hide')) {
      document.getElementById("buttonCall").classList.add('show');
      document.getElementById("buttonCall").classList.remove('hide');
    }
    this.pullParamsUrl();
    this.getCenters();
    this.getBranches();
    this.getList();
    this.getCourse();
    this.getClasses();
    this.getReasonByTicketId();
    this.showReason();
    this.getObjectFeedback();
    this.getSource();
    this.getDepartments();
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    pullParamsUrl() {
      this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1
      this.query.ticket_id = this.$route.query.ticket_id ? parseInt(this.$route.query.ticket_id) : ''
      this.query.keyword = this.$route.query.keyword
      this.query.category = this.$route.query.category ? parseInt(this.$route.query.category) : ''
      this.query.status = this.$route.query.status ? [parseInt(this.$route.query.status)] : ''
      this.query.exam = this.$route.query.exam ? parseInt(this.$route.query.exam) : ''
      this.query.feedback_stars = this.$route.query.feedback_stars ? [parseInt(this.$route.query.feedback_stars)] : ''
      this.query.center_ids = this.$route.query.center_ids ? [parseInt(this.$route.query.center_ids)] : ''
      this.query.branch_ids = this.$route.query.branch_ids ? [parseInt(this.$route.query.branch_ids)] : ''
      this.query.course_ids = this.$route.query.course_ids ? [parseInt(this.$route.query.course_ids)] : ''
      this.query.class_ids = this.$route.query.class_ids ? [parseInt(this.$route.query.class_ids)] : ''
      this.query.created_at = this.$route.query.created_at ? this.$route.query.created_at : []
      this.query.reason_id = this.$route.query.reason_id ? parseInt(this.$route.query.reason_id) : ''
    },
    handleCenter() {
      this.branches = [];
      this.courses = [];
      this.query.branch_ids = [];
      this.query.branch_ids = [];

      this.getCourse();
      this.getBranches();
    },
    handleCourse() {
      this.query.class_ids = [];
      this.classes = [];
      this.getClasses();
    },
    async handleCategory() {
      this.query.reason_id = '';
      await this.resetShow();
      this.getReasonByTicketId();
      this.showFilter.star = false;
      this.showFilter.exam = false;
    },
    getReasonByTicketId() {
      switch (this.query.category) {
        case 1:
          this.is_show_reasons = true;
          this.getListReason();
          this.text_reason = 'nghỉ';
          break;
        case 6:
          this.is_show_reasons = true;
          this.getListReason();
          this.text_reason = 'feedback';
          break;
        default:
          this.is_show_reasons = false;
      }
    },
    checkShowReason() {
      this.query.reason_id = '';
      this.showReason();
    },
    showReason() {
      switch (this.query.category) {
        case 1:
          this.is_show_reason = true;
          this.getListReason();
          this.text_reason = 'nghỉ';
          break;
        case 6:
          this.is_show_reason = true;
          this.getListReason();
          this.text_reason = 'feedback';
          break;
        default:
          this.is_show_reason = false;
      }
    },
    getListReason() {
      let category = this.query.category;
      this.$store.dispatch(GET_TICKET_REASON_BY_TYPE, category).then((data) => {
        if (!data.error) {
          this.reasons = data.data;
        }
      })
    },
    checkShowColumn() {
      switch (this.query.category) {
        //Nghỉ học 2 buổi
        case 1:
          this.show.lesson_leave = true;
          break;
        //Điểm kiểm tra thấp
        case 2:
          this.showFilter.exam = true;
          this.show.exam_type = true;
          this.show.exam_total_score = true;
          break;
        //Feedback thấp
        case 6:
          this.showFilter.star = true;
          this.show.feedback_star = true;
          this.show.feedback_comment = true;
          break;
      }
    },

    resetShow() {
      this.show.lesson_leave = false;
      this.show.exam_type = false;
      this.show.exam_total_score = false;
      this.show.feedback_star = false;
      this.show.feedback_comment = false;
    },
    getCenters() {
      this.$store.dispatch(GET_CENTERS, {
        status: 1,
        limit: 100000
      }).then((data) => {
        if (!data.error) {
          this.centers = data.data.data;
        }
      })
    },
    getBranches() {
      this.$store.dispatch(GET_BRANCHES, {
        status: 1,
        limit: 100000,
        center_ids: this.query.center_ids
      }).then((data) => {
        if (!data.error) {
          this.branches = data.data.data;
        }
      })
    },

    getCourse() {
      this.$store.dispatch(GET_COURSES, {
        status: 1,
        per_page: 100000,
        only_filter: 1,
        center_ids: this.query.center_ids
      }).then((data) => {
        if (!data.error) {
          this.courses = data.data.data;
        }
      })
    },
    getClasses() {
      this.$store.dispatch(GET_CLASSES, {
        status: 1,
        limit: 100000,
        course_id: this.query.course_ids
      }).then((data) => {
        if (!data.error) {
          this.classes = data.data.data;
        }
      })
    },
    clickCallback(obj) {
      this.page = obj;
      this.pushParamsUrl();
      this.getList()
    },
    getList() {
      this.is_disable_search = true;
      this.$store.dispatch(GET_ALL_TICKET,
        {
          page: this.page,
          ...this.query
        }).then((data) => {
          this.tickets = data.data;
          this.last_page = data.data.last_page;
          this.is_disable_search = false;
          this.checkShowColumn();
        }).catch((error) => {
          console.log(error);
        })
    },
    search() {
      this.page = 1;
      this.pushParamsUrl();
      this.getList();
      this.handleCategory();
    },
    pushParamsUrl() {
      this.$router.push({
        path: '', query: {
          page: this.page,
          ...this.query
        }
      })
    },
    exportExcel() {
      this.is_export = true;
      let HEADER_ROW_EXPORT_TICKETS_CSHV = [
        {
          key: 'stt',
          value: 'STT',
        },
        {
          key: 'id',
          value: 'Mã ticket',
        },
        {
          key: 'name',
          value: 'Họ và tên',
        },
        {
          key: 'phone',
          value: 'Số điện thoại',
        },
        {
          key: 'email',
          value: 'Email',
        },
        {
          key: 'birthday',
          value: 'Ngày sinh',
        },
        {
          key: 'center_name',
          value: 'Trung tâm học',
        },
        {
          key: 'branch_name',
          value: 'Chi nhánh học',
        },
        {
          key: 'course_name',
          value: 'Khóa học',
        },
        {
          key: 'classroom_name',
          value: 'Lớp học',
        },
        {
          key: 'status',
          value: 'Trạng thái ticket',
        },
        { // nghi lien tiep
          key: 'lesson_leave',
          value: 'Các buổi nghỉ',
        },
        { //diem thap
          key: 'exam_type',
          value: 'Buổi kiếm tra',
        },
        {
          key: 'exam_total_score',
          value: 'Kết quả',
        },
        { // feedback thấp
          key: 'feedback_star',
          value: 'Số sao',
        },
        {
          key: 'feedback_comment',
          value: 'Nội dung đánh giá',
        },
        {
          key: 'ticket_reason',
          value: 'Lý do',
        },
        {
          key: 'ticket_reason',
          value: 'Lý do feedback',
        },
        {
          key: 'ticket_reason',
          value: 'Lý do nghỉ',
        },
        {
          key: 'created_at',
          value: 'Thời gian tạo',
        },
      ]
      this.$store.dispatch(EXPORT_EXCEL_TICKET, this.query).then((data) => {
        if (this.query.category == 1) { // nghi hoc 2 buoi
          HEADER_ROW_EXPORT_TICKETS_CSHV = HEADER_ROW_EXPORT_TICKETS_CSHV.filter(function( obj ) {
            return (obj.value != 'Buổi kiếm tra' && obj.value != 'Kết quả' && obj.value != 'Số sao' &&
                obj.value != 'Nội dung đánh giá' && obj.value != 'Lý do' && obj.value != 'Lý do feedback')
          });
        } else if (this.query.category == 2) { // diem kiem tra thap
          HEADER_ROW_EXPORT_TICKETS_CSHV = HEADER_ROW_EXPORT_TICKETS_CSHV.filter(function( obj ) {
            return (obj.value != 'Các buổi nghỉ' && obj.value != 'Số sao' &&
                obj.value != 'Nội dung đánh giá' && obj.value != 'Lý do nghỉ' && obj.value != 'Lý do feedback')
          });
        } else if (this.query.category == 6) { // feedback thấp
          HEADER_ROW_EXPORT_TICKETS_CSHV = HEADER_ROW_EXPORT_TICKETS_CSHV.filter(function( obj ) {
            return (obj.value != 'Các buổi nghỉ' && obj.value != 'Buổi kiếm tra' && obj.value != 'Kết quả' &&
                obj.value != 'Lý do' && obj.value != 'Lý do nghỉ')
          });
        } else if (this.query.category == 10) { // BTVN ko tot
          HEADER_ROW_EXPORT_TICKETS_CSHV = HEADER_ROW_EXPORT_TICKETS_CSHV.filter(function( obj ) {
          return (obj.value != 'Các buổi nghỉ' && obj.value != 'Buổi kiếm tra' && obj.value != 'Kết quả' &&
              obj.value != 'Số sao' && obj.value != 'Nội dung đánh giá' &&
              obj.value != 'Lý do feedback' && obj.value != 'Lý do nghỉ')
          });
        } else {
          HEADER_ROW_EXPORT_TICKETS_CSHV = HEADER_ROW_EXPORT_TICKETS_CSHV.filter(function( obj ) {
            return (obj.value != 'Lý do feedback' && obj.value != 'Lý do nghỉ')
          });
        }
        exportExcel(HEADER_ROW_EXPORT_TICKETS_CSHV, data.data, "Danh sách ticket.xlsx", true)
        this.noticeMessage('success', 'Thành công', data.message);
      }).catch((err) => this.noticeMessage('error', 'Thất bại', err?.data.message))
        .finally(() => this.is_export = false);
    },
    exportExcelV2() {
      this.is_export = true;
      let HEADER_ROW_EXPORT_TICKETS_CSHV = [
        {
          key: 'stt',
          value: 'STT',
        },
        {
          key: 'ticket_id',
          value: 'Mã ticket',
        },
        {
          key: 'name',
          value: 'Họ và tên',
        },
        {
          key: 'phone',
          value: 'Số điện thoại',
        },
        {
          key: 'email',
          value: 'Email',
        },
        {
          key: 'birthday',
          value: 'Ngày sinh',
        },
        {
          key: 'center_name',
          value: 'Trung tâm học',
        },
        {
          key: 'branch_name',
          value: 'Chi nhánh học',
        },
        {
          key: 'course_name',
          value: 'Khóa học',
        },
        {
          key: 'classroom_name',
          value: 'Lớp học',
        },
        {
          key: 'status',
          value: 'Trạng thái ticket',
        },
        { // nghi lien tiep
          key: 'lesson_leave',
          value: 'Các buổi nghỉ',
        },
        { //diem thap
          key: 'exam_type',
          value: 'Buổi kiếm tra',
        },
        {
          key: 'exam_total_score',
          value: 'Kết quả',
        },
        { // feedback thấp
          key: 'feedback_star',
          value: 'Số sao',
        },
        {
          key: 'feedback_comment',
          value: 'Nội dung đánh giá',
        },
        {
          key: 'ticket_reason',
          value: 'Lý do',
        },
        {
          key: 'ticket_reason',
          value: 'Lý do feedback',
        },
        {
          key: 'ticket_reason',
          value: 'Lý do nghỉ',
        },
        {
          key: 'created_at',
          value: 'Thời gian tạo',
        },
      ]
      this.$store.dispatch(EXPORT_EXCEL_TICKET_V2, this.query).then((data) => {
        if (this.query.category == 1) { // nghi hoc 2 buoi
          HEADER_ROW_EXPORT_TICKETS_CSHV = HEADER_ROW_EXPORT_TICKETS_CSHV.filter(function( obj ) {
            return (obj.value != 'Buổi kiếm tra' && obj.value != 'Kết quả' && obj.value != 'Số sao' &&
                obj.value != 'Nội dung đánh giá' && obj.value != 'Lý do' && obj.value != 'Lý do feedback')
          });
        } else if (this.query.category == 2) { // diem kiem tra thap
          HEADER_ROW_EXPORT_TICKETS_CSHV = HEADER_ROW_EXPORT_TICKETS_CSHV.filter(function( obj ) {
            return (obj.value != 'Các buổi nghỉ' && obj.value != 'Số sao' &&
                obj.value != 'Nội dung đánh giá' && obj.value != 'Lý do nghỉ' && obj.value != 'Lý do feedback')
          });
        } else if (this.query.category == 6) { // feedback thấp
          HEADER_ROW_EXPORT_TICKETS_CSHV = HEADER_ROW_EXPORT_TICKETS_CSHV.filter(function( obj ) {
            return (obj.value != 'Các buổi nghỉ' && obj.value != 'Buổi kiếm tra' && obj.value != 'Kết quả' &&
                obj.value != 'Lý do' && obj.value != 'Lý do nghỉ')
          });
        } else if (this.query.category == 10) { // BTVN ko tot
          HEADER_ROW_EXPORT_TICKETS_CSHV = HEADER_ROW_EXPORT_TICKETS_CSHV.filter(function( obj ) {
            return (obj.value != 'Các buổi nghỉ' && obj.value != 'Buổi kiếm tra' && obj.value != 'Kết quả' &&
                obj.value != 'Số sao' && obj.value != 'Nội dung đánh giá' &&
                obj.value != 'Lý do feedback' && obj.value != 'Lý do nghỉ')
          });
        } else {
          HEADER_ROW_EXPORT_TICKETS_CSHV = HEADER_ROW_EXPORT_TICKETS_CSHV.filter(function( obj ) {
            return (obj.value != 'Lý do feedback' && obj.value != 'Lý do nghỉ')
          });
        }
        exportExcel(HEADER_ROW_EXPORT_TICKETS_CSHV, data.data, "Danh sách ticket.xlsx", true)
        this.noticeMessage('success', 'Thành công', data.message);
      }).catch((err) => this.noticeMessage('error', 'Thất bại', err?.data.message))
          .finally(() => this.is_export = false);
    },

    createTicket() {
      this.dialogCreateTicket = true;
    },
    emitUpdateFile(e) {
      this.fileList = e;
    },
    getSource() {
      this.sources = [];
      this.$store.dispatch(GET_SOURCE).then((data) => {
        for (const key in data.data) {
          this.sources.push({
            id: key,
            name: data.data[key]
          })
        }
      })
    },
    getDepartments() {
      this.$store.dispatch(GET_DEPARTMENT, {
        all: true,
        status: 1
      }).then((data) => {
        this.departments = data.data;
      })
    },
    getObjectFeedback() {
      this.objectFeedback = [];
      this.$store.dispatch(GET_OBJECT_FEEDBACK).then((data) => {
        for (const key in data.data) {
          this.objectFeedback.push({
            id: key,
            name: data.data[key]
          })
        }
      })
    },
    changeCategoryName(category_chamsoc)
    {
      let text = '';
      switch (category_chamsoc) {
        case '1':
          text = 'Nghỉ 2 buổi';
          break;
        case '2':
          text = 'Điểm kiểm tra thấp';
          break;
        case '6':
          text = 'Feedback thấp';
          break;
        case '10':
          text = 'Kết quả BTVN không tốt';
          break;
        case '11':
          text = 'Kết quả BTVN không tốt';
          break;
        case '13':
          text = 'Nhắc nhở bảo hành';
          break;
        case '14':
          text = 'Nghỉ 1 buổi không phép';
          break;
        case '15':
          text = 'Nghỉ buổi khai giảng';
          break;
        case '16':
          console.log(text, 'aaa')
          text = 'Chăm sóc học viên giữa khóa';
          break;
      }
      return text;
    }
  }
}
</script>

<style scoped>
.bg-violet {
  background: #5E00B6;
}
</style>
